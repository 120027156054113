import { render, staticRenderFns } from "./customerManagement.vue?vue&type=template&id=70217c32&scoped=true&"
import script from "./customerManagement.vue?vue&type=script&lang=js&"
export * from "./customerManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70217c32",
  null
  
)

export default component.exports